
<template>
  <div class="questions-view-container">
    <div class="questions-view-questions">
      <div class="questions-view-frame33">
        <span class="questions-view-text">
          <span>你对理想的生态生活有怎样的畅想？</span>
        </span>
        
        <div class="questions-view-frame31">
          <input
          type="text"
          placeholder="点击这里输入"
          class="questions-view-input"
          id="inputField"
          @input="updateCharacterCount"
        />

          <div class="questions-view-frame311">
            <div class="questions-view-frame312">
              <span class="questions-view-text02"><span>示例</span></span>
              <button class="questions-view-seemorebtn" @click="changeExampleSentences">
                <span class="questions-view-text04"><span>再看一批</span></span>
              </button>
            </div>
            <div class="questions-view-frame313">
              <span class="questions-view-text06">
                <span>
                  {{exampleSentenceSlot1}}
                </span>
              </span>
              <span class="questions-view-text08">
                <span>
                  {{exampleSentenceSlot2}}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="questions-view-frame331">
          <div class='loader hidden' id="spinner"></div>

          <span class="questions-view-text2">
            <span>已写<span id="total-character-count">{{ totalCharacterCount }}</span>字，<span id="prompt-text">还需写</span><span id="prompt-character-count">3</span>字</span>
          </span>

          <primary-button :onClick="post" :disabled="!statisfy" text="填好了" id="post-button"></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import primaryButton from '@/components/primary-button.vue';
import router from '@/router';
export default {
  name: 'QuestionsView',

  metaInfo: {
    title: '望梦之洲',
  },
  components: {
    primaryButton,
  },
  data() {
    return {
      totalCharacterCount: 0,
      RequiredCharacter: 3,
      MaxCharacter:40,
      statisfy: false,
      terraID: '',
      exampleSentences:["我想在森林里漫步","没有全球变暖","和大家一起在森林里露营","在河边散步"],
      exampleSentenceSlot1:"我想在森林里漫步",
      exampleSentenceSlot2:"没有全球变暖",
      currentSentenceIndex :3,

    };
  },
  
  methods: {
    changeExampleSentences(){
      if(this.currentSentenceIndex+3<this.exampleSentences.length){
        this.currentSentenceIndex = this.currentSentenceIndex+3;
      }
      else{
        this.currentSentenceIndex = 0;
      }
      this.exampleSentenceSlot1 = this.exampleSentences[this.currentSentenceIndex];
      this.exampleSentenceSlot2 = this.exampleSentences[this.currentSentenceIndex+1];
      
    },

    updateCharacterCount(event) {
      this.totalCharacterCount = event.target.value.length;
      if(this.totalCharacterCount<this.RequiredCharacter){
        document.getElementById("prompt-text").innerHTML = "还需写";
        document.getElementById("prompt-character-count").innerHTML = Math.abs(this.RequiredCharacter - this.totalCharacterCount);
        this.statisfy = false;
      }
      else if (this.totalCharacterCount >= this.RequiredCharacter && this.totalCharacterCount <= this.MaxCharacter) {
        document.getElementById("prompt-text").innerHTML = "还可再写";
        document.getElementById("prompt-character-count").innerHTML = Math.abs(this.MaxCharacter - this.totalCharacterCount);
        this.statisfy = true;

      } else {
        document.getElementById("prompt-text").innerHTML = "已超出";
        document.getElementById("prompt-character-count").innerHTML = Math.abs(this.MaxCharacter - this.totalCharacterCount);
        this.statisfy = false;
      }
    },
    post() {
        document.getElementById("post-button").disabled = true;
        document.getElementById("spinner").classList.remove('hidden');
        // document.getElementById("post-button").text = "提交中";
        // document.getElementById("post-button").showIcon = false;
        const text = document.getElementById('inputField').value;
        //the text will be sent to the server
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "name":"anonymous",
          "iconUrl": "anonymous",
          "content": text,
          "terraID":this.terraID,
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("https://api.capsuleer.me/terraisle/internal/post", requestOptions)
          // .then(response => response.text())
          .then(result => {
            console.log(result);
            setTimeout(() => {
              document.getElementById("spinner").classList.add('hidden');
          //after 2 seconds, the page will be redirected to the end page
            router.replace('/end');
          }, 2000);
          })
          .catch(error => {
            console.log('error', error);
            
            this.$root.$toast.open(
              {
                message: '提交失败 '+error,
                type: 'error',
                position: 'bottom',
              });
              document.getElementById("spinner").classList.add('hidden');

              // document.getElementById("post-button").setAttribute("text","提交失败") ;
              setTimeout(() => {
                //after 2 seconds, the page will be redirected to the end page
                document.getElementById("post-button").disabled = false;
                }, 2000);

            // console.log(toast);
          });

      
    }
  },
    created() {
    function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
          }
        }
        return null;
      }
    var tempTerraID = getCookie('unique_id');
    if(tempTerraID == null)
    {
      // this.$root.$toast.open(
      //         {
      //           message: '请稍等... ',
      //           type: 'warning',
      //           position: 'bottom',
      //         });
      // console.log('no terraID,falling back')
      if(window.location.href.indexOf('localhost') > -1){
        return;
    }
      router.replace('/');
    }else{
      this.terraID = tempTerraID;
    }
  },
  
}
</script>


<style scoped>
.hidden{
  display:none;
}

.loader {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-top: 5px solid #efefef;
  border-right: 5px solid rgba(255, 255, 255, 0.5);
  border-bottom: 5px solid rgba(255, 255, 255, 0.5);
  border-left: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.questions-view-text2{
  font-family: 'Noto Sans SC';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;

color: #C9C9C9;
}
.questions-view-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.questions-view-questions {
  gap: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 60px 0;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 252, 1);
}
.questions-view-frame33 {
  display: flex;
  padding: 60px 0 0 0;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  max-height: 800px;
}
.questions-view-text {
  color: rgba(68, 68, 68, 1);
  width: 296px;
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-frame31 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.questions-view-input {
  gap: 10px;
  color: rgba(200, 200, 200, 1);
  width: 288px;
  display: flex;
  padding: 11px 0;
  overflow: hidden;
  font-size: 20px;
  text-align: left;
  align-items: flex-start;
  flex-shrink: 0;
  font-family: Noto Sans SC;
  font-weight: 500;
  border-color: rgba(200, 200, 200, 1);
  border-style: solid;
  border-width: Symbol(figma.mixed)px;
}
.questions-view-frame311 {
  gap: 10px;
  width: 296px;
  display: flex;
  padding: 15px 16px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(237, 242, 224, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 17px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(184, 214, 157, 0.07000000029802322);
}
.questions-view-frame312 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.questions-view-text02 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-seemorebtn {
  gap: 10px;
  display: flex;
  align-items: flex-start;
}
.questions-view-text04 {
  color: rgba(119, 150, 136, 1);
  height: auto;
  font-size: 10px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-frame313 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.questions-view-text06 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 12px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-text08 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 12px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-frame331 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.questions-view-text10 {
  color: rgba(200, 200, 200, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-btn {
  gap: 11px;
  width: 186px;
  display: flex;
  padding: 17px 25px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(51, 129, 94, 0.17000000178813934);
}
.questions-view-text12 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.questions-view-s-gcheck1 {
  width: 24px;
  height: 24px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C9C9C9;
  opacity: 1; /* Firefox */
}
.questions-view-input {
  gap: 10px;
  color: rgba(68, 68, 68, 1);
  
  width: 288px;
  display: flex;
  padding: 11px 0;
  overflow: hidden;
  font-size: 20px;
  text-align: left;
  align-items: flex-start;
  flex-shrink: 0;
  font-family: Noto Sans SC;
  font-weight: 500;
  border-color: #C9C9C9;
  border-style: solid;
  border-width: 2px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.questions-view-input:focus{
  outline: none;

}
input:focus::placeholder {
  color: transparent;
}
</style>
